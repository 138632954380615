:root {
  /* spacing values */
  --space-unit: 1em;
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));

  /* components padding */
  --component-padding: var(--space-sm);
}

// optional - edit space unit and padding of all components at a specific breakpoint
@media only screen and (max-width: 600px) {
  :root {
    --space-unit: 1em;
    --component-padding: var(--space-md);
  }
}

/* vertical margins */
.margin-top,
.margin-top--md {
  margin-top: var(--space-md);
}

.margin-top--xxxs {
  margin-top: var(--space-xxxs);
}

.margin-top--xxs {
  margin-top: var(--space-xxs);
}

.margin-top--xs {
  margin-top: var(--space-xs);
}

.margin-top--sm {
  margin-top: var(--space-sm);
}

.margin-top--lg {
  margin-top: var(--space-lg);
}

.margin-top--xl {
  margin-top: var(--space-xl);
}

.margin-top--xxl {
  margin-top: var(--space-xxl);
}

.margin-bottom,
.margin-bottom--md {
  margin-bottom: var(--space-md);
}

.margin-bottom--xxxs {
  margin-bottom: var(--space-xxxs);
}

.margin-bottom--xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom--xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom--sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom--lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom--xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom--xxl {
  margin-bottom: var(--space-xxl);
}

// @media only screen and (max-width: 600px) {
//   .margin-top,
//   .margin-top--md {
//     margin-top: var(--space-lg);
//   }

//   .margin-top--sm {
//     margin-top: var(--space-md);
//   }

//   .margin-top--lg {
//     margin-top: var(--space-xl);
//   }

//   .margin-top--xl {
//     margin-top: var(--space-xxl);
//   }

//   .margin-top--xxl {
//     margin-top: var(--space-xxxl);
//   }

//   .margin-bottom,
//   .margin-bottom--md {
//     margin-bottom: var(--space-lg);
//   }

//   .margin-bottom--sm {
//     margin-bottom: var(--space-md);
//   }

//   .margin-bottom--lg {
//     margin-bottom: var(--space-xl);
//   }

//   .margin-bottom--xl {
//     margin-bottom: var(--space-xxl);
//   }

//   .margin-bottom--xxl {
//     margin-bottom: var(--space-xxxl);
//   }
// }
