@import './spacing.scss';
@import './typography.scss';

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: blue;
}
p {
  margin: 0px;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
}
input {
  text-transform: inherit;
}

$gray-darkest: #1c1c1c;
$gray-dark: #4c4c4c;
$gray-light: #7c7c7c;
$gray-lightest: #acacac;
$border-gray: #dadce0;
$primary-color: #007aff;
$primary-light: #007aff10;
$warning-color: #ffcc00;
$warning-light: #ffcc0010;
$error-color: #ff3b30;
$error-light: #ff3b3010;
$success-color: #34c759;
$success-light: #34c75910;

:root {
  --primary-color: #007aff;
  --gray-light: #7c7c7c;
  --gray-lightest: #acacac;
  --gray-darkest: #1c1c1c;
  --warning-color: #ffcc00;
  --error-color: #ff3b30;
  --success-color: #34c759;
  --border-gray: #dadce0;
}

@mixin border-bottom {
  border-bottom: 1px solid $border-gray;
}

@mixin flex {
  display: flex;
  align-items: center;
}

// Stripe card element styling

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

// End of stripe card element styling

// Start of dot loader styling

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary-color;
  color: $primary-color;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary-color;
  color: $primary-color;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary-color;
  color: $primary-color;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

// End of dot loader styling

// maintenance page
.maintenance-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 50%;
    max-width: 600px;
    min-width: 300px;
  }
  h1 {
    text-align: center;
  }
}

// end of maintenance page

.flex-container {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.direction-column {
  flex-direction: column;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.text-center {
  text-align: center;
}

// instasearch css
.ais-RefinementList-item--selected .ais-RefinementList-checkbox,
.ais-GeoSearch-input:checked {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23007aff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E') !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
.ais-RefinementList-item--selected .ais-RefinementList-checkbox:focus,
.ais-GeoSearch-input:checked:focus {
  box-shadow: var(--primary-color) 0 1px 0 0 inset, currentcolor 0 0 0 1px !important;
}
.ais-SearchBox-input:focus {
  border-color: var(--primary-color) !important;
}
.ais-CurrentRefinements-list {
  display: flex !important;
  flex-wrap: wrap;
}
.ais-CurrentRefinements-item {
  flex-wrap: wrap;
}

// inline dot loader
.inline-loader {
  width: 100%;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#007aff 90%,#0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}
