.logoContainer {
  display: flex;
  align-items: center;

  .actionButton {
    margin: 15px;
  }

  .logo {
    display: flex;
    margin: 0px 10px;

    .psypack {
      display: flex;

      .large_logo {
        width: 110px;
      }
      .small_logo {
        width: 40px;
      }
    }
  }
}
