@import '../../../../styles/index.scss';

.error_wrapper {
  height: 100vh;
  width: 100%;

  .error_container {
    display: flex;
    height: 85%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .error {
      font-size: 1.5rem;
      color: $gray-light;
    }
    .button {
      width: 300px;
      background-color: $primary-color;
      margin-top: 50px;
    }
  }
}
